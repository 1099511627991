.card__title {
  margin: 0;
  font-size: 1.6rem;
}
.card__description {
  font-size: 1rem;
  color: #79797e;
}
.card__details {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0;
}
.card__delete-btn {
  z-index: 2;
  position: absolute;
  top: 10px;
  right: 10px;
}
