.new-event {
  height: 100vh;
  background-image: -webkit-linear-gradient(
    65deg,
    #fd6a4c 50%,
    var(--global-company-color) 50%
  );
  display: flex;
  justify-content: center;
  align-items: center;
}
.new-event__section {
  padding: 24px 44px;
  background-color: white;
  box-shadow: 20px 20px 16px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  text-align: center;
  width: auto;
}
.create-event__img {
  visibility: hidden;
  position: absolute;
}
#thumbnail {
  width: 180px;
  height: 180px;
  padding: 24px;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: contain;
  background-position: center center;
}
.create-event {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 32px;
}
.create-event__left {
  float: 0.5;
}
.create-event__right {
  flex: 0.5;
}
.create-event__right * {
  margin-bottom: 8px;
}
.create-event__placeholder {
  width: 50px;
  height: 50px;
}
.create-event__input {
  display: block;
}

@media screen and (max-width: 768px) {
  .new-event__section {
    padding: 16px 24px;
  }
  .create-event {
    flex-direction: column;
  }
}
@media screen and (max-height: 500px) {
  .new-event {
    height: 800px;
  }
}
