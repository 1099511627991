
@tailwind base;
@tailwind components;
@tailwind utilities;

 .about {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.about__img {
  flex-basis: calc(50%);
}
.about__img img {
  border-radius: 0.4rem;
}
.about__header {
  flex-basis: calc(50% - 2rem);
}
.black {
  background-color: #353839;
}
@media screen and (max-width: 786px) {
  .about {
    flex-direction: column;
  }
  .about__img {
    padding: 2rem;
  }
  .about__header {
    padding: 2rem;
  }
} 
