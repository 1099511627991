@tailwind base;
@tailwind components;
@tailwind utilities;


.services {
  /*margin: 2rem 0;*/
  width: 100%;
  display: flex;
}
.blog {
  margin-top: 2rem;
  margin-bottom: 2rem;
  text-align: center;
}
.callto {
  background-image: -webkit-linear-gradient(
    65deg,
    #fd6a4c 50%,
    var(--global-company-color) 50%
  );
  margin-bottom: 0;
  padding: 0 1rem;
  color: white;
 
}

.callto h2{
  font-size:2.5em;
}
.callto__wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
}
/*RESPONSIVENESS*/

@media screen and (max-width: 768px) {
  .services {
    flex-direction: column;
  }
  .blog__entries {
    flex-direction: column;
  }
  .article {
    flex-basis: 16rem;
  }
}
