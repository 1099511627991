.footer{
  background-color:black;
  color:#fff;
  padding:2rem 0;
}

.footer-container{
  margin:0 auto;
  padding:1.25rem;
  max-width:1200px;
}
.footer-grid{
  display:grid;
  grid-template-columns:1fr;
  gap:2rem;
}

@media(min-width:768px){
  .footer-grid{
    grid-template-columns:repeat(3,1fr);
  }
}

.footer-title{
font-weight:bold;
font-size:1.125rem;
margin-bottom:1rem;
color: white;
}
.footer-link{
  margin-bottom:1rem;
}

.footer-link:hover{ 
  text-decoration:underline;
}
.social-media{
  display:flex;
  gap:1rem;
}
.social-media-link{
  padding:0.5rem;
  border-radius:100%;
  transition:background-color 0.3s;
}
.social-media-link.facebook{
  background-color: #3b5998;;
}
.social-media-link.facebook:hover{
  background-color: #2e5d8c;
}
.social-media-link.twitter{
  background-color:#1da1f2;
}

.social-media-link.twitter:hover{
  background-color: #1991db;
}
.social-media-link.instagram {
  background-color: #e1306c; /* Instagram Pink */
}

.social-media-link.instagram:hover {
  background-color: #c13584; /* Darker Instagram Pink */
}
.footer-bottom{
  display:flex;
  margin:0 auto;
  justify-content:center;
  padding:1.25rem;
  margin:1.25rem 0;
  border-top:1px solid #fff;
}
.footer-bottom p {
  margin: 0; /* Remove default margin */
}

.footer-bottom .highlight {
  color: orange; /* For highlighted year */
}