.article {
  flex-basis: calc(33.33% - 1.2rem);
  height: 24rem;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 2rem;
  box-sizing: border-box;
  overflow: hidden;
  cursor: pointer;
}
.article__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.article__title {
  color: white;
  margin: 1rem 0;
  z-index: 2;
}
.article__author {
  margin: 0;
  color: whitesmoke;
  z-index: 2;
}

.blog__entries {
  display: flex;
  justify-content: space-between;
}
.article__bg::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 10%,
    rgba(0, 0, 0, 0.2) 40%,
    rgba(0, 0, 0, 0.7) 100%,
    rgba(0, 0, 0, 0.9) 100%,
    rgba(0, 0, 0, 1) 80%
  );
}
