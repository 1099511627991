.hero {
  /* background-image: url(../../images/mainheropage.png); */
  background-image: url("https://plaon-react.envytheme.com/images/football/footb-main-banner1.jpg");
  /* background-image: url(../../assets//football-background-grass-with-copyspace-right.jpg); */
  height: 100vh;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  
  color: white;
  display: flex;
  align-items: center;
  z-index: 1;
  animation: fadeIn 1s;
}
.hero__wrapper {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-column-gap: 0;
}
.container {
  max-width: 968px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}
.hero__grid {
  grid-column: 1 / span 6;
}

.hero__text {
  font-size: 4rem;
  margin-bottom: 1rem;
  animation-delay: 0.6s;
}
.hero__wrapper h3 {
  font-size: 2rem;
  margin: 0;
  animation-delay: 0.8s;
}
.hero__wrapper p {
  font-size: 1.4rem;
  animation-delay: 1s;
}

@media screen and (max-width: 968px) {
  .hero__grid {
    grid-column: 1 / span 10;
    padding: 0 2rem;
  }
  .hero__text {
    font-size: 3rem;
    margin-bottom: 1rem;
  }
  .hero__wrapper h3 {
    font-size: 1.8rem;
    margin: 0;
  }
  .hero__wrapper p {
    font-size: 1.2rem;
  }
}
/*CUSTOM ANIMATIONS*/
.animate-pop-in {
  animation: pop-in 0.6s cubic-bezier(0, 0.9, 0.3, 1.2) forwards;
  opacity: 0;
}
@keyframes fade-slide-down {
  0% {
    opacity: 0;
    transform: translateY(-4rem);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
@keyframes pop-in {
  0% {
    opacity: 0;
    transform: translateY(-4rem) scale(0.8);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
@keyframes rotate-up {
  100% {
    transform: rotateZ(-4deg);
  }
}
