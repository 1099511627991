.bg {
  background-color: var(--global-company-color);
}
.wt {
  color: white;
}
.center {
  text-align: center;
}
.feature__img {
  width: 80px;
  height: 80px;
}
.features {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  text-align: center;
}
.feature {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 200px;
  padding: 1rem;
  margin-top: 1rem;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}
.feature:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
